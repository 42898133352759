import DomainApplication from "./DomainApplication";
import { Guid } from "guid-typescript";
import Site from "@/models/Site";

export default class Feb {
  public id: string;
  public repereEquipements: string;
  public unity: string;
  public unityState: boolean;
  public workDescription: string;
  public prescriptions: string;
  public niveauPlancherTravail: string;
  public accessNumber: string;
  public chargeSpecifiquePlanchers: string|null;
  public nombrePlancherTravail: string|null;
  public bachageFiletCoupeVent: boolean;
  public bachageIgnifuge: boolean;
  public echafaudageSuspendu: boolean;
  public echafaudageRoulant: boolean;
  public echafaudageInterne: boolean;
  public echafaudageGardeCorps: boolean;
  public echafaudageTypePasserelle: boolean;

  constructor() {
    this.id = Guid.createEmpty().toString();
    this.repereEquipements = "";
    this.unity = "";
    this.unityState = true;
    this.workDescription = "";
    this.prescriptions = "";
    this.niveauPlancherTravail = "";
    this.accessNumber = "";
    this.chargeSpecifiquePlanchers = null;
    this.nombrePlancherTravail = null;
    this.bachageFiletCoupeVent = false;
    this.bachageIgnifuge = false;
    this.echafaudageSuspendu = false;
    this.echafaudageRoulant = false;
    this.echafaudageInterne = false;
    this.echafaudageGardeCorps = false;
    this.echafaudageTypePasserelle = false;
  }
}
